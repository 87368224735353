import { render, staticRenderFns } from "./FlashGroupList.vue?vue&type=template&id=2b9ef078&scoped=true&"
import script from "./FlashGroupList.vue?vue&type=script&lang=js&"
export * from "./FlashGroupList.vue?vue&type=script&lang=js&"
import style0 from "./FlashGroupList.vue?vue&type=style&index=0&lang=css&"
import style1 from "./FlashGroupList.vue?vue&type=style&index=1&id=2b9ef078&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9ef078",
  null
  
)

export default component.exports